const KEYURI = {


        // BACKEND_URI:"http://localhost:5000/api"

        BACKEND_URI:"https://site-2-sycamore-server-2-bgx2k.ondigitalocean.app/api"
                
}

export default KEYURI

